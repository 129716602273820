import React, { useState, useEffect } from "react"
import path from "path"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import { withTheme, useTheme } from "@material-ui/core/styles"
import Breadcrumbs from "@material-ui/core/Breadcrumbs"
import Container from "@material-ui/core/Container"
import Toolbar from "@material-ui/core/Toolbar"
import DoubleArrow from "@material-ui/icons/DoubleArrow"
import { ComponentBreadcrumbQuery } from "../../types/graphql-types"

const useSize = () => {
  const isClient = typeof window === "object"

  function getSize() {
    return {
      width: isClient ? window.innerWidth : undefined,
      height: isClient ? window.innerHeight : undefined,
    }
  }

  const [windowSize, setWindowSize] = useState(getSize)

  useEffect(() => {
    if (!isClient) {
      return false
    }

    function handleResize() {
      setWindowSize(getSize())
    }

    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("resize", handleResize)
  }, [])

  return windowSize
}

const Wrapper = withTheme(styled(props => (
  <Container {...props}>{props.children}</Container>
))`
  ${({ theme }) => `
    && {
      .item {
        color: #737373;
        font-size: 0.875rem;
        text-decoration: none;
        display: flex;
        &:last-child() {
          color: #464646;
        }

        .icon {
          marginRight: ${theme.spacing(0.5)}px;
          width: 20;
          height: 20;
        }
      }
    }
  `}
`)

type BreadcrumbItem = { name: string; slug: string }
type Props = {
  showNav?: boolean
  maxWidth?: "md" | "xs" | "sm" | "lg" | "xl"
  itemList: Array<BreadcrumbItem>
}

const Component: React.FC<Props> = (props: Props) => {
  const { width } = useSize()
  const theme = useTheme()

  const data = useStaticQuery<ComponentBreadcrumbQuery>(
    graphql`
      query ComponentBreadcrumb {
        site {
          siteMetadata {
            siteUrl
          }
        }
      }
    `
  )

  const siteUrl: string = data.site.siteMetadata.siteUrl
  const maxWidth = props.maxWidth || "md"
  const itemList: Array<BreadcrumbItem> = props.itemList

  const json = {
    "@context": "http://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [],
  }

  itemList.forEach((v, k) => {
    json.itemListElement.push({
      "@type": "ListItem",
      position: k + 1,
      name: v.name,
      item: `${siteUrl}${path.sep}${v.slug}`,
    })
  })

  return (
    <React.Fragment>
      <Helmet>
        <script type="application/ld+json">{JSON.stringify(json)}</script>
      </Helmet>
      {props.showNav && (
        <Wrapper maxWidth={maxWidth}>
          <Toolbar variant="dense">
            <Breadcrumbs
              itemsAfterCollapse={0}
              itemsBeforeCollapse={2}
              maxItems={width < theme.breakpoints.values.md ? 2 : 8}
              aria-label="breadcrumb"
            >
              {itemList.map((v, k) => {
                return (
                  <Link className={"item"} key={k} to={`${path.sep}${v.slug}`}>
                    {k === 0 ? <DoubleArrow className={"icon"} /> : null}
                    {v.name}
                  </Link>
                )
              })}
            </Breadcrumbs>
          </Toolbar>
        </Wrapper>
      )}
    </React.Fragment>
  )
}

export const Breadcrumb = Component
