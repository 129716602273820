import React, { useState } from "react"
import path from "path"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import { withTheme } from "@material-ui/core/styles"
import SpeedDial from "@material-ui/lab/SpeedDial"
import SpeedDialAction from "@material-ui/lab/SpeedDialAction"
import Close from "@material-ui/icons/Close"
import Share from "@material-ui/icons/Share"
import Twitter from "@material-ui/icons/Twitter"
import Facebook from "@material-ui/icons/Facebook"
import { HatenaBookmark, Line } from "../components/icon"
import { ComponentSnsShareQuery } from "../../types/graphql-types"

type Props = {
  title?: string
  slug?: string
  className?: string
}

const Component: React.FC<Props> = (props: Props) => {
  const [open, setOpen] = useState<boolean>(false)
  const [icon, setIcon] = useState<React.ReactNode>(<Share />)

  const data: ComponentSnsShareQuery = useStaticQuery<ComponentSnsShareQuery>(
    graphql`
      query ComponentSnsShare {
        site {
          siteMetadata {
            title
            siteUrl
            social {
              twitter
            }
          }
        }
      }
    `
  )
  const title: string = props.title
    ? `${props.title} : ${data.site.siteMetadata.title}`
    : data.site.siteMetadata.title

  const url: string = props.slug
    ? `${data.site.siteMetadata.siteUrl}${path.sep}${props.slug}`
    : data.site.siteMetadata.siteUrl

  const { twitter } = data.site.siteMetadata.social

  const handleSpeedDialClick = (
    e: React.MouseEvent<HTMLElement, MouseEvent>
  ): void => {
    setOpen(!open)
    open ? setIcon(<Share />) : setIcon(<Close />)
  }

  const handleTwitterActionClick = (
    e: React.MouseEvent<HTMLElement, MouseEvent>
  ): void => {
    e.stopPropagation()
    const openUrl: string = `https://twitter.com/share?text=${encodeURIComponent(
      title
    )}&url=${encodeURIComponent(url)}&via=${encodeURIComponent(
      twitter
    )}&lang=ja`

    window.open(openUrl, "_blank")
  }

  const handleFacebookActionClick = (
    e: React.MouseEvent<HTMLElement, MouseEvent>
  ): void => {
    const openUrl: string = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
      url
    )}`
    e.stopPropagation()
    window.open(openUrl, "_blank")
  }

  const handleHatenaBookmarkActionClick = (
    e: React.MouseEvent<HTMLElement, MouseEvent>
  ): void => {
    const openUrl: string = `https://b.hatena.ne.jp/entry/panel/?url=${encodeURIComponent(
      url
    )}&title=${encodeURIComponent(props.title)}`
    e.stopPropagation()
    window.open(openUrl, "_blank")
  }

  const handleLineActionClick = (
    e: React.MouseEvent<HTMLElement, MouseEvent>
  ): void => {
    const openUrl: string = `https://social-plugins.line.me/lineit/share?url=${encodeURIComponent(
      url
    )}`
    e.stopPropagation()
    window.open(openUrl, "_blank")
  }

  return (
    <SpeedDial
      ariaLabel="SNS で共有"
      className={props.className}
      hidden={false}
      icon={icon}
      open={open}
      onClick={handleSpeedDialClick}
      direction={"left"}
      style={{
        position: "fixed",
        bottom: "1rem",
        right: "1rem",
      }}
    >
      <SpeedDialAction
        className={"line"}
        icon={<Line />}
        tooltipTitle={"Line で送る"}
        onClick={handleLineActionClick}
      />
      <SpeedDialAction
        className={"hatenaBookmark"}
        icon={<HatenaBookmark />}
        tooltipTitle={"はてなブックマーク"}
        onClick={handleHatenaBookmarkActionClick}
      />
      <SpeedDialAction
        className={"facebook"}
        icon={<Facebook />}
        tooltipTitle={"シェア"}
        onClick={handleFacebookActionClick}
      />
      <SpeedDialAction
        className={"twitter"}
        icon={<Twitter />}
        tooltipTitle={"ツイート"}
        onClick={handleTwitterActionClick}
      />
    </SpeedDial>
  )
}

export const SnsShare = withTheme(styled(Component)`
  ${({ theme }) => `
    && {
      .MuiSpeedDial-fab {
        background-color: #ffa726;
        &:hover { background-color: #ffa726; }
      }
      
      .facebook {
        background-color: #3b5998;
        color: #fff;
        &:hover {
          background-color: #3b5998;
          color: #fff;
        }
      }
  
      .twitter {
        background-color: #55acee;
        color: #fff;
        &:hover {
          background-color: #55acee;
          color: #fff;
        }
      }

      .hatenaBookmark {
        background-color: #00A4DE;
        color: #fff;
        &:hover {
          background-color: #00A4DE;
          color: #fff;
        }
      }

      .line {
        background-color: #28B900;
        color: #fff;
        &:hover {
          background-color: #28B900;
          color: #fff;
        }
      }
    }
  `}
`)
