import React from "react"
import path from "path"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import { ComponentArticleSchemaQuery } from "../../types/graphql-types"

type Props = {
  title: string
  published: Date
  updated: Date | null
  image: string | null
  description: string
  slug: string
}

const Component: React.FC<Props> = (props: Props) => {
  const data = useStaticQuery<ComponentArticleSchemaQuery>(
    graphql`
      query ComponentArticleSchema {
        site {
          siteMetadata {
            title
            siteUrl
          }
        }
      }
    `
  )

  const logo: string = `${data.site.siteMetadata.siteUrl}${path.sep}logo.png`
  const headline: string = props.title || data.site.siteMetadata.title
  const datePublished: string = props.published.toISOString()
  const image: string = props.image
    ? `${data.site.siteMetadata.siteUrl}${props.image}`
    : `${data.site.siteMetadata.siteUrl}${path.sep}ogp.png`

  const json = {
    "@context": "http://schema.org",
    "@type": "Article",
    headline: headline,
    datePublished: datePublished,
    image: image,
    author: {
      "@type": "Organization",
      name: "Nilay",
    },
    publisher: {
      "@type": "Organization",
      name: "Nilay",
      logo: {
        "@type": "ImageObject",
        url: logo,
      },
    },
    mainEntityOfPage: `${data.site.siteMetadata.siteUrl}${path.sep}${props.slug}`,
    description: props.description,
  }

  if (props.updated) json["dateModified"] = props.updated.toISOString()

  return (
    <Helmet>
      <script type="application/ld+json">{JSON.stringify(json)}</script>
    </Helmet>
  )
}

export const ArticleSchema = Component
