import React from "react"
import path from "path"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import { SeoQuery } from "../../types/graphql-types"

type Props = {
  title?: string
  lang?: string
  charSet?: string
  description?: string
  slug?: string
  image?: string
}

const Component: React.FC<Props> = (props: Props) => {
  const data: SeoQuery = useStaticQuery<SeoQuery>(
    graphql`
      query Seo {
        site {
          siteMetadata {
            title
            siteUrl
            description
            social {
              twitter
              facebook
              facebookAppId
            }
          }
        }
      }
    `
  )

  const title: string = props.title
    ? `${props.title} : ${data.site.siteMetadata.title}`
    : `${data.site.siteMetadata.title} | 銃砲・射撃・狩猟の情報サイト`

  const description: string =
    props.description || data.site.siteMetadata.description

  const url: string = props.slug
    ? `${data.site.siteMetadata.siteUrl}${path.sep}${props.slug}`
    : data.site.siteMetadata.siteUrl

  const ogTitle: string = props.title || data.site.siteMetadata.title
  const ogImage: string = props.image
    ? `${data.site.siteMetadata.siteUrl}${props.image}`
    : `${data.site.siteMetadata.siteUrl}${path.sep}ogp.png`

  const twSite: string = `@${data.site.siteMetadata.social.twitter}`
  const twImage: string = props.image
    ? `${data.site.siteMetadata.siteUrl}${props.image}`
    : `${data.site.siteMetadata.siteUrl}${path.sep}twitter.png`

  const jsonOrg = {
    "@context": "http://schema.org",
    "@type": "Organization",
    name: "Nilay",
    url: "https://about.nilay.jp",
    logo: `${data.site.siteMetadata.siteUrl}/logo.png`,
    sameAs: [
      `https://twitter.com/${data.site.siteMetadata.social.twitter}`,
      `https://www.facebook.com/${data.site.siteMetadata.social.facebook}`,
    ],
  }

  return (
    <Helmet>
      <html lang={props.lang} />

      <title>{title}</title>

      <meta charSet={props.charSet} />
      <meta name="description" content={description} />

      <meta name="twitter:card" content="summary" />
      <meta name="twitter:site" content={twSite} />
      <meta name="twitter:creator" content={twSite} />
      <meta property="twitter:image" content={twImage} />

      <meta property="og:url" content={url} />
      <meta property="og:title" content={ogTitle} />
      <meta property="og:type" content="article" />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={ogImage} />

      <meta
        property="fb:app_id"
        content={data.site.siteMetadata.social.facebookAppId}
      />

      <link rel="canonical" href={url} />

      <script type="application/ld+json">{JSON.stringify(jsonOrg)}</script>
    </Helmet>
  )
}

Component.defaultProps = {
  lang: "ja",
  charSet: "UTF-8",
}

export const SEO: React.FC<Props> = Component
