import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"
import Container from "@material-ui/core/Container"
import Grid from "@material-ui/core/Grid"
import IconButton from "@material-ui/core/IconButton"
import Twitter from "@material-ui/icons/Twitter"
import Facebook from "@material-ui/icons/Facebook"
import Instagram from "@material-ui/icons/Instagram"
import YouTube from "@material-ui/icons/YouTube"
import GitHub from "@material-ui/icons/GitHub"
import RssFeedIcon from "@material-ui/icons/RssFeed"
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp"
import { withTheme } from "@material-ui/core/styles"
import { ComponentsFooterQuery } from "../../../types/graphql-types"

const FooterListItem = styled.a`
  display: block;
  line-height: 48px;
  height: 48px;
  margin: 0;
  width: auto;
  text-align: left;
  color: #fff;
  font-size: 1rem;
  text-decoration: none;
  &: hover {
    color: #fff;
  }
`

const FooterListItemDesc = styled.span`
  display: block;
  line-height: 1.75;
  height: 36px;
  margin: 0;
  width: 100%;
  text-align: left;
  color: #eee;
  font-size: 0.75rem;
  text-decoration: none;
`

const FooterListTitle = styled.div`
  display: block;
  line-height: 36px;
  height: 36px;
  margin: 0;
  width: 100%;
  text-align: left;
  color: #fff;
  font-size: 1rem;
  margin-bottom: 1rem;
  font-weight: 700;
`

const Fragment = withTheme(styled.div`
  ${({ theme }) => `
    color: #fff;
    background-color: #546e7a;
    margin-top: ${theme.spacing(8)}px;
    & .license {
      color: #fff;
      background-color: #526974;
      line-height: 1.75;
      padding-top: ${theme.spacing(2)}px;
      padding-bottom: ${theme.spacing(2)}px;
      & a { 
        color: #fff;
        text-decoration: none;
      }
    }
    & .lastFooter {
      background: #475b65;
    }
    & .scrollToTopButton {
      width: 100%;
      border-radius: 0;
    }
  `}
`)

export type Copyright = {
  publishDate: Date
}

type Props = { slug: string; copyright: Copyright }

const Component: React.FC<Props> = (props: Props) => {
  const {
    site: { siteMetadata: data },
  } = useStaticQuery<ComponentsFooterQuery>(
    graphql`
      query ComponentsFooter {
        site {
          siteMetadata {
            service {
              gunman
              ecommerce
              about
            }
            siteUrl
            social {
              youtube
              twitter
              instagram
              github
              facebook
            }
            title
          }
        }
      }
    `
  )

  const handleScrollToTopBUttonClick = (): void => {
    const elm = document.querySelector("body")

    if (elm) {
      elm.scrollIntoView({
        behavior: "smooth",
        block: "start",
      })
    }
  }

  return (
    <Fragment>
      <Container maxWidth="md">
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <FooterListTitle>Services</FooterListTitle>
            <FooterListItem
              href={data.service.ecommerce}
              target="_blank"
              rel="noopener noreferrer"
            >
              通信販売
            </FooterListItem>
            <FooterListItemDesc>
              射撃・狩猟・鳥獣被害対策用品を販売しています。
            </FooterListItemDesc>
            <FooterListItem
              href={data.service.gunman}
              target="_blank"
              rel="noopener noreferrer"
            >
              Gunman
            </FooterListItem>
            <FooterListItemDesc>
              申請・申込書の作成、火薬類管理サービスを提供しています。
            </FooterListItemDesc>
            <FooterListItem
              href={data.service.about}
              target="_blank"
              rel="noopener noreferrer"
            >
              About
            </FooterListItem>
            <FooterListItemDesc>
              お知らせの掲載、試験的なツールの公開をしています。
            </FooterListItemDesc>
          </Grid>

          <Grid item xs={12} md={6}>
            <FooterListTitle>Contents</FooterListTitle>
            <FooterListItem href="/">トップページ</FooterListItem>
            <FooterListItem href="/articles/1597956932">
              申請・申込書
            </FooterListItem>
            <FooterListItem href="/articles">記事一覧</FooterListItem>
            <FooterListItem href="/about">案内所</FooterListItem>
          </Grid>

          <Grid item xs={12}>
            <IconButton
              href={`https://twitter.com/${data.social.twitter}`}
              aria-label="Twitter"
              component={"a"}
              color="inherit"
              edge="start"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Twitter />
            </IconButton>
            <IconButton
              href={`https://www.facebook.com/${data.social.facebook}`}
              aria-label="Facebook"
              component={"a"}
              color="inherit"
              edge="start"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Facebook />
            </IconButton>
            <IconButton
              href={`https://www.youtube.com/channel/${data.social.youtube}`}
              aria-label="YouTube"
              component={"a"}
              color="inherit"
              edge="start"
              target="_blank"
              rel="noopener noreferrer"
            >
              <YouTube />
            </IconButton>
            <IconButton
              href={`https://www.instagram.com/${data.social.instagram}`}
              aria-label="Instagram"
              component={"a"}
              color="inherit"
              edge="start"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Instagram />
            </IconButton>
            <IconButton
              href={`https://github.com/${data.social.github}`}
              aria-label="GitHub"
              component={"a"}
              color="inherit"
              edge="start"
              target="_blank"
              rel="noopener noreferrer"
            >
              <GitHub />
            </IconButton>
            <IconButton
              to="/feed.xml"
              aria-label="Feed"
              component={Link}
              color="inherit"
              edge="start"
            >
              <RssFeedIcon />
            </IconButton>
          </Grid>
        </Grid>
      </Container>

      <Grid item xs={12} className={"license"}>
        <Container maxWidth="md">
          <small>
            特に表示のない限り、このウェブサイトの文章を「
            <a
              href="https://creativecommons.org/licenses/by-sa/4.0/deed.ja"
              target="_blank"
              rel="noopener noreferrer"
            >
              クリエイティブ・コモンズ 表示-継承 4.0 国際 ライセンス
            </a>
            」の下で公開しています。
          </small>
          <br />
          <small>
            © {props.copyright.publishDate.getFullYear()}{" "}
            <a
              href={`${data.siteUrl}/${props.slug}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {data.title}
            </a>
          </small>
        </Container>
      </Grid>
      <Grid item xs={12} className={"lastFooter"}>
        <IconButton
          onClick={() => handleScrollToTopBUttonClick()}
          className={"scrollToTopButton"}
          color="inherit"
          aria-label="scroll to top on page"
          component="div"
        >
          <KeyboardArrowUpIcon fontSize="large" />
        </IconButton>
      </Grid>
    </Fragment>
  )
}

export const Footer: React.FC<Props> = Component
