import React from "react"
import jss from "jss"
import styled from "styled-components"
import CssBaseline from "@material-ui/core/CssBaseline"
import { createMuiTheme } from "@material-ui/core"
import {
  StylesProvider,
  ThemeProvider,
  createGenerateClassName,
} from "@material-ui/core/styles"
import "typeface-roboto"

const theme = createMuiTheme({})

const generateClassName = createGenerateClassName({
  productionPrefix: "some",
})

type Props = {
  className?: string
  children?: React.ReactNode
}

const Component: React.FC<Props> = (props: Props) => {
  return (
    <ThemeProvider theme={theme}>
      <div className={props.className}>
        <CssBaseline />
        {props.children}
      </div>
    </ThemeProvider>
  )
}

const StyledComponent = styled(Component)`
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Noto Sans JP",
    "Segoe UI", "Hiragino Sans", "Noto Sans CJK JP", "Yu Gothic", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Sans Emoji";
`

export const Layout = StyledComponent
