import React, { useState } from "react"
import { Link } from "gatsby"
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles"
import MuiAppBar from "@material-ui/core/AppBar"
import Button from "@material-ui/core/Button"
import Container from "@material-ui/core/Container"
import Drawer from "@material-ui/core/Drawer"
import IconButton from "@material-ui/core/IconButton"
import Toolbar from "@material-ui/core/Toolbar"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"
import Close from "@material-ui/icons/Close"
import Home from "@material-ui/icons/Home"
import ShoppingCart from "@material-ui/icons/ShoppingCart"
import Menu from "@material-ui/icons/Menu"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: { backgroundColor: "#37474f" },
    item: {
      "& + &": { marginLeft: theme.spacing(2) },
      [theme.breakpoints.down("sm")]: { display: "none" },
    },
    shoppingItem: { marginLeft: "auto" },
    menuButton: { [theme.breakpoints.up("md")]: { display: "none" } },
    drawer: { width: "264px" },
    drawerHeader: { backgroundColor: "#37474f" },
  })
)

type Props = {}

const Component: React.FC<Props> = (props: Props) => {
  const classes = useStyles()
  const [isDrawerOpen, setDrawerOpen] = useState<boolean>(false)

  const toggleDrawer = (open: boolean) => (
    event: React.KeyboardEvent | React.MouseEvent
  ) => {
    if (
      event.type === "keydown" &&
      ((event as React.KeyboardEvent).key === "Tab" ||
        (event as React.KeyboardEvent).key === "Shift")
    ) {
      return
    }

    setDrawerOpen(open)
  }

  return (
    <React.Fragment>
      <MuiAppBar className={classes.root} position="relative">
        <Container maxWidth="md">
          <Toolbar>
            <IconButton
              onClick={toggleDrawer(true)}
              className={classes.menuButton}
              color="inherit"
              aria-label="open drawer"
              edge="start"
            >
              <Menu />
            </IconButton>
            <IconButton
              to="/"
              aria-label="トップページ"
              component={Link}
              color="inherit"
              edge="start"
              className={classes.item}
            >
              <Home />
            </IconButton>
            <Button
              to="/articles"
              aria-label="記事一覧"
              component={Link}
              color="inherit"
              className={classes.item}
            >
              記事一覧
            </Button>
            <Button
              to="/articles/1597956932"
              aria-label="申請書類"
              component={Link}
              color="inherit"
              className={classes.item}
            >
              申請書類
            </Button>
            <Button
              to="/about"
              aria-label="案内所"
              component={Link}
              color="inherit"
              className={classes.item}
            >
              案内所
            </Button>
            <Button
              href="https://www.nilay.jp"
              target="_blank"
              rel="noopener noreferrer"
              color="inherit"
              startIcon={<ShoppingCart />}
              className={classes.shoppingItem}
            >
              Shopping
            </Button>
          </Toolbar>
        </Container>
      </MuiAppBar>
      <Drawer
        role="presentation"
        onClick={toggleDrawer(false)}
        onKeyDown={toggleDrawer(false)}
        open={isDrawerOpen}
      >
        <div className={classes.drawer}>
          <MuiAppBar position="relative" className={classes.drawerHeader}>
            <Container>
              <Toolbar>
                <IconButton
                  aria-label="clise drawser"
                  color="inherit"
                  edge="start"
                >
                  <Close />
                </IconButton>
              </Toolbar>
            </Container>
          </MuiAppBar>

          <List>
            <ListItem component={Link} to="/articles" divider={true}>
              <ListItemText primary="記事一覧" />
            </ListItem>
            <ListItem component={Link} to="/articles/1597956932" divider={true}>
              <ListItemText primary="申請書類" />
            </ListItem>
            <ListItem component={Link} to="/about">
              <ListItemText primary="案内所" />
            </ListItem>
          </List>
        </div>
      </Drawer>
    </React.Fragment>
  )
}

export const AppBar: React.FC<Props> = Component
